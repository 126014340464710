<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5" v-show="is_loaded">
        <section class="grid grid-cols-1 md:grid-cols-1 gap-6">
          <ValidationObserver  v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <div>
              <h1 class="title">圖片(1200*750)<span>*</span></h1>
                <draggable
                  tag="div"
                  class="grid grid-cols-6 gap-6"
                  v-model="imgs"
                  draggable=".image_wrap"
                  :animation="150"
                  dragClass="dragClass"
                  chosenClass="chosenClass"
                  filter=".unmover"
                  :forceFallback="true"
                >
                  <div v-for="(item, index) in imgs" :key="index" class="image_wrap group">
                    <div class="image_func invisible group-hover:visible">
                      <button @click="imgs.splice(index, 1)" title="刪除圖片" class="absolute top-2 right-2 hover:opacity-50 unmover">
                        <svg-icon icon-class="cancel" className="white"></svg-icon>
                      </button>
                    </div>
                    <img class="w-full h-full object-contain" :src="item" />
                  </div>
                  <div class="upload" slot="footer">
                    <div class="flex flex-col items-center">
                      <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                      <p class="text-gray-400 text-xs">點擊上傳或拖曳圖片</p>
                    </div>
                    <input
                      type="file"
                      multiple
                      @change="uploadImg"
                      accept="image/*"
                      class="border-2 absolute z-10 h-full w-full cursor-pointer opacity-0"
                    />
                  </div>
                </draggable>
                <ValidationProvider mode="aggressive" name="圖" placeholder="請選擇圖" rules="required|min_value:1" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="_imgsLength" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
            </div>
            <div>
              <h1 class="title">啟用狀態 <span>*</span></h1>
              <vs-switch v-model="status" class="w-24" color="success">
                <template #on>
                    開
                </template>
                <template #off>
                    關
                </template>
              </vs-switch>
            </div>
            <div>
              <h1 class="title">標題 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="標題" placeholder="請輸入標題" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="name" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">租金 <span>*</span></h1>
              <div class="flex">
                <ValidationProvider mode="aggressive" name="租金" placeholder="請輸入租金" rules="required|required|double|min_value:0|max:10" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-32" v-model="price" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
                <div class="w-32">
                  <vs-select v-model="price_unit" color="success">
                    <vs-option :label="item.price_name" :value="item.id" v-for="item in def_price_unit" :key="item.id">
                      {{ item.price_name }}
                    </vs-option>
                  </vs-select>
                </div>  
              </div>
            </div>
            <div>
              <h1 class="title">地址 <span>*</span></h1>
              <div class="flex">
                <ValidationProvider
                  tag="div"
                  class="relative"
                  name="縣市"
                  rules="required|max:20"
                  v-slot="{ errors }"
                >
                  <vs-select @change="countyHandler" class="w-full" v-model="county">
                    <vs-option v-for="county in TW_districts" :key="county.name" :label="county.name" :value="county.name">{{ county.name }}</vs-option>
                  </vs-select>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>

                <ValidationProvider
                  tag="div"
                  class="relative"
                  name="鄉鎮市區"
                  rules="required|max:20"
                  v-slot="{ errors }"
                >
                  <vs-select :key="_district.districts.length"  class="w-full" v-model="district">
                    <vs-option v-for="district in _district.districts" :key="district.name" :label="district.name" :value="district.name">{{
                      district.name
                    }}</vs-option>
                  </vs-select>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>

                <!-- <ValidationProvider mode="aggressive" name="縣市" placeholder="請輸入縣市" rules="required|max:30" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-40" v-model="county" placeholder="請輸入縣市" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
                <ValidationProvider mode="aggressive" name="區域" placeholder="請輸入區域" rules="required|max:30" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-40" v-model="district" placeholder="請輸入區域" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider> -->
              </div>
              <ValidationProvider mode="aggressive" name="地址" placeholder="請輸入地址" rules="required|max:100" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="address" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">入住時間 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="入住時間" placeholder="請輸入入住時間" rules="required|max:30" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-40" type="date" :max="_today" v-model="checkin" ></vs-input>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">出租狀態 <span>*</span></h1>
              <div class="w-32">
                <vs-select v-model="rent" color="success">
                  <vs-option :label="item.rent_name" :value="item.id" v-for="item in def_rent" :key="item.id">
                    {{ item.rent_name }}
                  </vs-option>
                </vs-select>
              </div>  
            </div>
            <div>
              <div class="flex">
                <h1 class="title">標籤資訊 <span>*</span></h1>
              </div> 
              <div class="flex flex-row flex-wrap">
                <div v-for="rtag in rtags" :key="rtag.id">
                  <vs-checkbox class="checkbox_section" v-model="rtag.ishas" :val="rtag.id"><span class="ml-2">{{ rtag.name }}</span></vs-checkbox>
                </div>
                <ValidationProvider mode="aggressive" name="標籤" placeholder="請選擇標籤" rules="required|min_value:1" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="_rtagsLength" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <h1 class="title">房/廳/衛/坪 <span>*</span></h1>
              <div class="flex">
                <ValidationProvider mode="aggressive" name="房數" placeholder="請輸入房數" rules="required|integer|min_value:0|max:10" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-32" v-model.number="rstyle1" :min=0 />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider> <p class="ml-1 mr-1 pt-3">/</p>
                <ValidationProvider mode="aggressive" name="廳數" placeholder="請輸入廳數" rules="required|integer|min_value:0|max:10" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-32" v-model.number="rstyle2" :min=0 />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider> <p class="ml-1 mr-1 pt-3">/</p>
                <ValidationProvider mode="aggressive" name="衛數" placeholder="請輸入衛數" rules="required|integer|min_value:0|max:10" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-32" v-model.number="rstyle3" :min=0 />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider> <p class="ml-1 mr-1 pt-3">/</p>
                <ValidationProvider mode="aggressive" name="坪數" placeholder="請輸入坪數" rules="required|double|min_value:0|max:10" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-32" v-model="rstyle4" :min=0 />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <h1 class="title">樓層 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="樓層" placeholder="請輸入樓層" rules="required|max:30" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="floor" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">房型樣式 <span>*</span></h1>
              <vs-select v-model="rstyle_id" color="success" ref="rstyles" @change="displaySelect('rstyles')">
                <vs-option :label="item.name" :value="item.id" v-for="item in rstyles" :key="item.id">
                  {{ item.name }}
                </vs-option>
              </vs-select>
            </div>
            <div>
              <h1 class="title">型態資訊 <span>*</span></h1>
              <vs-select v-model="rtype_id" color="success" ref="rtypes" @change="displaySelect('rtypes')">
                <vs-option :label="item.name" :value="item.id" v-for="item in rtypes" :key="item.id">
                  {{ item.name }}
                </vs-option>
              </vs-select>
            </div>
            <div>
              <h1 class="title">房型簡介 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="房型簡介" placeholder="請輸入房型簡介" rules="required" v-slot="{ errors }" tag="div" class="relative">
                <editor ref="editor" v-model="content1" :content="content1"></editor>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">房間資訊 <span>*</span></h1>
              <div class="flex flex-row flex-wrap">
                <div v-for="rdevice in rdevices" :key="rdevice.id">
                  <vs-checkbox class="checkbox_section" v-model="rdevice.ishas" :val="rdevice.id"><span class="ml-2">{{ rdevice.name }}</span></vs-checkbox>
                  <vs-input class="w-32" v-model="rdevice.descs" />
                </div>
                <ValidationProvider mode="aggressive" name="房間資訊" placeholder="請選擇房間資訊" rules="required|min_value:1" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="_rdevicesLength" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <h1 class="title">費用說明 <span>*</span></h1>
              <div class="flex flex-row flex-wrap">
                <div v-for="rservice in rservices" :key="rservice.id">
                  <vs-checkbox class="checkbox_section" v-model="rservice.ishas" :val="rservice.id"><span class="ml-2">{{ rservice.name }}</span></vs-checkbox>
                  <vs-input class="w-32" v-model="rservice.descs" />
                </div>
                <ValidationProvider mode="aggressive" name="費用說明" placeholder="請選擇費用說明" rules="required|min_value:1" v-slot="{ errors }" tag="div" class="relative">
                  <vs-input class="w-full" v-model="_rservicesLength" style="display:none;"/>
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <h1 class="title">其他需求 <span>*</span></h1>
              <div class="flex flex-row flex-wrap">
                <div v-for="rneed in rneeds" :key="rneed.id">
                  <vs-checkbox class="checkbox_section" v-model="rneed.ishas" :val="rneed.id"><span class="ml-2">{{ rneed.name }}</span></vs-checkbox>
                </div>
              </div>
              <ValidationProvider mode="aggressive" name="其他需求" placeholder="請選擇其他需求" rules="required|min_value:1" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="_rneedsLength" style="display:none;"/>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">周邊景點 </h1>
              <editor ref="editor" v-model="content2" :content="content2"></editor>
            </div>
            <div>
              <h1 class="title">附近店點 </h1>
              <vs-input class="w-full" v-model="nearby_stores" />
            </div>
            <div>
              <h1 class="title">賞屋影片URL</h1>
              <vs-input class="w-full" v-model="youtube" />
            </div>
            <div>
              <h1 class="title">負責業務 <span>*</span></h1>
              <vs-select v-model="user_id" color="success" ref="salesmans" @change="displaySelect('salesmans')" :disabled="salesman_status">
                <vs-option v-for="item in salesmans" :label="item.name" :value="item.id" :key="item.id">
                  {{ item.name }}
                </vs-option>
              </vs-select>
            </div>
            <div class="flex justify-between">
              <vs-button size="large" color="danger" border @click="delete_dialog = true" v-if="id != 0">
                <span class="font-medium">刪除</span>
              </vs-button>
              <div class="w-4"></div>
              <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="saveBtn(invalid)">
                <span class="font-medium">儲存</span>
              </vs-button>
            </div>
 
          </ValidationObserver>
        </section>
      </main>

       <!-- 骨架屏區塊 -->
      <main class="space-y-5" v-show="!is_loaded">
        <section class="flex items-center space-x-4 justify-between">
        <skeleton width="48px" />
        <div v-if="!isNewProduct" class="flex lg:w-full items-center justify-between">
          <skeleton class="hidden lg:block" width="50%" height="40px" />
          <div class="flex items-center space-x-2">
            <skeleton width="82px" />
            <skeleton width="82px" />
          </div>
        </div>
      </section>
      </main>
    </div>

    <!-- 刪除 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除{{ title }}資料？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的{{ title }}資料無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteBtn" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import TW_districts from '@/assets/json/TW_districts.json' // 台灣縣市 郵遞區號 列表
import draggable from 'vuedraggable'
import editor from '@/components/editor.vue'
import { timeStamp } from 'console';

export default {
  name: 'room',
  components: {
    editor,
    draggable
  },
  data() {
    return {
      fname: 'rooms',
      title: '物件',
      is_loaded: false,
      delete_dialog: false,
      TW_districts: TW_districts, // 台灣縣市 郵遞區號 列表
      def_price_unit: [
        {
          'id': 0,
          'price_name': '時',
        },
        {
          'id': 1,
          'price_name': '日',
        },
        {
          'id': 2,
          'price_name': '月',
        },
        {
          'id': 3,
          'price_name': '年',
        }
      ],
      def_rent: [
        {
          'id': 0,
          'rent_name': '已出租',
        },
        {
          'id': 1,
          'rent_name': '招租中',
        }
      ],
      id: 0,
      imgs: [],
      //標籤資訊
      rtags: [
        // {
        //   id: 1,
        //   name: '熱門',
        //   ishas: false,
        // }
      ],
      //房型樣式
      rstyles: [
        // {
        //   id: 1,
        //   name: '整層住家',
        // }
      ],
      //型態資訊
      rtypes: [
        // {
        //   id: 1,
        //   name: '公寓',
        // }
      ],
      //房間資訊
      rdevices: [
        // {
        //   id: 1,
        //   img: '',
        //   name: '最短租期',
        //   descs: '1個月',
        //   ishas: false,
        // }
      ],
      //費用說明
      rservices: [
        // {
        //   id: 1,
        //   name: '押金',
        //   descs: '20,000',
        //   ishas: false,
        // }
      ],
      //其他需求
      rneeds: [
        // {
        //   id: 1,
        //   name: '陽台',
        //   ishas: false,
        // }
      ],
      //負責業務
      salesmans: [
        // {
        //   id: 1,
        //   name: '業務',
        // }
      ],
      // name: '復興路經國學院旁套房',
      // price: 5000,
      // price_unit: 2,
      // zipcode: '330',
      // county: '桃園市',
      // district: '桃園區',
      // address: '中山路1號',
      // checkin: '12/15',
      // rent: 1,
      // rstyle1: 3,
      // rstyle2: 2,
      // rstyle3: 2,
      // rstyle4: 35.2,
      // floor: '16/20',
      // content1: '房型簡介',
      // content2: '周邊景點',
      // nearby_stores: '附近店點',
      // youtube: 'https://www.youtube.com/watch?v=HD8K9HL1Du4',
      // rstyle_id: 1,
      // rtype_id: 1,
      // status: true,
      // user_id: 1,
      name: '',
      price: 0,
      price_unit: 2,
      zipcode: '',
      county: '',
      district: '',
      address: '',
      checkin: '',
      rent: 1,
      rstyle1: 0,
      rstyle2: 0,
      rstyle3: 0,
      rstyle4: 0,
      floor: '',
      content1: '',
      content2: '',
      nearby_stores: '',
      youtube: '',
      rstyle_id: 0,
      rtype_id: 0,
      status: true,
      user_id: 0,
      salesman_status: true,
      //圖片------------------------------------------------
      previewIndex: undefined, // 預覽圖片的索引
      alt: '',
      previewDialog: false, // dialog預覽
    }
  },
  computed: {
    // 篩選出鄉鎮市區
    _district() {
      let city = this.county

      if (city) return this.TW_districts.find((item) => item.name === city)
      else return null
    },
    // 今天日期
    _today() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()

      function plusZero(value) {
        if (value < 10) return '0' + value
        else return value
      }

      return `${year}-${plusZero(month)}-${plusZero(day)}`
    },
    _imgsLength() {
      return this.imgs.length;
    },
    _rdevicesLength() {
      let len = 0;
      this.rdevices.forEach((item, index) => {
        if(item.ishas) len++;
      })
      return len;
    },
    _rservicesLength() {
      let len = 0;
      this.rservices.forEach((item, index) => {
        if(item.ishas) len++;
      })
      return len;
    },
    _rneedsLength() {
      let len = 0;
      this.rneeds.forEach((item, index) => {
        if(item.ishas) len++;
      })
      return len;
    },
    _rtagsLength() {
      let len = 0;
      this.rtags.forEach((item, index) => {
        if(item.ishas) len++;
      })
      return len;
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.id = this.$route.params.id
      this.is_loaded = true
      if (this.id) {
        this.$route.meta.title = `編輯${this.title}資料`       
        this.initData('edit')
        this.loadData()
      }else{
        this.id = 0
        this.$route.meta.title = `新增${this.title}資料`
        this.initData('create')
      }
    },
    initData(key) {
      this.$axios({
        url: `backstage/rooms/room_relations`,
        method: 'get',
      }).then((res) => {
        // console.log(res)
        //類型1房間樣式2房間資訊3費用說明4其他需求5型態資訊6標籤資訊
        if (res.data.status) {
          this.rstyles = res.data.data.rstyles
          if(this.rstyles[0] != null){
            this.rstyle_id = this.rstyles[0]['id']
            this.$refs.rstyles.valueLabel = this.rstyles[0]['name']
          } 
          this.rdevices = res.data.data.rdevices
          this.rservices = res.data.data.rservices
          this.rneeds = res.data.data.rneeds
          this.rtypes = res.data.data.rtypes
          if(this.rtypes[0] != null && key == 'create'){
            this.rtype_id = this.rtypes[0]['id']
            this.$refs.rtypes.valueLabel = this.rtypes[0]['name']
          } 
          this.rtags = res.data.data.rtags
          this.salesmans = res.data.data.salesmans
          if(this.salesmans[0] != null && key == 'create'){
            this.user_id = this.salesmans[0]['id']
            this.$refs.salesmans.valueLabel = this.salesmans[0]['name']
          } 

          //判斷是否要鎖定業務角色
          let salesman_statusArr = this.$store.state.login.salesman_status.split(','); 
          if(salesman_statusArr[1] == 1) {
            this.salesman_status = true
            this.user_id = salesman_statusArr[0]
            this.$refs.salesmans.valueLabel = this.$store.state.login.uname
          }else if(key == 'create') {
            this.salesman_status = false
            this.user_id = salesman_statusArr[0]
            this.$refs.salesmans.valueLabel = this.$store.state.login.uname
          }else{
            this.salesman_status = false
          }

        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    loadData() {
      this.$axios({
        url: `backstage/rooms/room/${this.id}`,
        method: 'get',
      }).then((res) => {
        // console.log(res)
        if (res.data.status) {
          this.is_API_loaded = true
          this.imgs = res.data.data.imgs
          this.name = res.data.data.name
          this.price = res.data.data.price
          this.price_unit = res.data.data.price_unit
          this.zipcode = res.data.data.zipcode
          this.county = res.data.data.county
          this.district = res.data.data.district
          this.address = res.data.data.address
          this.checkin = res.data.data.checkin
          this.rent = res.data.data.rent
          this.rstyle1 = res.data.data.rstyle1
          this.rstyle2 = res.data.data.rstyle2
          this.rstyle3 = res.data.data.rstyle3
          this.rstyle4 = res.data.data.rstyle4
          this.floor = res.data.data.floor
          this.content1 = res.data.data.content1
          this.content2 = res.data.data.content2
          this.nearby_stores = res.data.data.nearby_stores
          this.youtube = res.data.data.youtube
          this.rstyle_id = res.data.data.rstyle_id
          this.rtype_id = res.data.data.rtype_id
          this.status = res.data.data.status
          this.user_id = res.data.data.user_id
          res.data.data.room_has_devices.forEach((item1, index1) => {
            this.rdevices.forEach((item2, index2) => {
              if(item1.device_id == item2.id){
                item2.descs = item1.descs
                item2.ishas = true
              }
            })
            this.rservices.forEach((item2, index2) => {
              if(item1.device_id == item2.id){
                item2.descs = item1.descs
                item2.ishas = true
              }
            })
            this.rneeds.forEach((item2, index2) => {
              if(item1.device_id == item2.id){
                item2.ishas = true
              }
            })
            this.rtags.forEach((item2, index2) => {
              if(item1.device_id == item2.id){
                item2.ishas = true
              }
            })
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    saveBtn(invalid) {
      if (this.id) {
        this.saveSubmit(`backstage/rooms/room/${this.id}`, 'put', invalid);
      }else{
        this.saveSubmit('backstage/rooms/room', 'post', invalid);
      }
    },
    saveSubmit(url, method, invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: url,
        method: method,
        data: {
          imgs: this.imgs,
          name: this.name,
          price: this.price,
          price_unit: this.price_unit,
          zipcode: this.zipcode,
          county: this.county,
          district: this.district,
          address: this.address,
          checkin: this.checkin,
          rent: this.rent,
          rstyle1: this.rstyle1,
          rstyle2: this.rstyle2,
          rstyle3: this.rstyle3,
          rstyle4: this.rstyle4,
          floor: this.floor,
          content1: this.content1,
          content2: this.content2,
          nearby_stores: this.nearby_stores,
          youtube: this.youtube,
          user_id: this.user_id,
          status: this.status,
          imgs: this.imgs,
          //類型1房間樣式2房間資訊3費用說明4其他需求5型態資訊6標籤資訊
          rstyle_id: this.rstyle_id,
          rdevices: this.rdevices,
          rservices: this.rservices,
          rneeds: this.rneeds,
          rtype_id: this.rtype_id,
          rtags: this.rtags,
        },

      }).then((res) => {
        loading.close()
        if(res){
           if (res.data.status) {
            this.$myVS.openNoti(res.data.message, '', undefined)
            this.$router.replace({
              name: this.fname,
              query: this.$route.query
            })
          } else {
            this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          }
        }
      })
    },
    deleteBtn() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: `backstage/rooms/room/${this.id}`,
        method: 'delete',
        data: {
        },
      }).then((res) => {
        loading.close() // 關閉按鈕loading
        if (res.data.status) {
          this.$myVS.openNoti(res.data.message, '', undefined)
          this.$router.replace({
            name: this.fname,
            query: this.$route.query
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    uploadImg(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const length = e.target.files.length
      for (let i = 0; i < length; i++) {
        const image = e.target.files[i]
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (e) => {
          this.imgs.push(e.target.result)
        }
      }
      e.target.value = ''
    },
    displaySelect(key) {
      if(key == 'rstyles'){
        this.rstyles.forEach((item, index) => {
          if(item.id == this.rstyle_id) this.$refs.rstyles.valueLabel = item.name
        });
      }else if(key == 'rtypes'){
        this.rtypes.forEach((item, index) => {
          if(item.id == this.rtype_id) this.$refs.rtypes.valueLabel = item.name
        });
      }else if(key == 'salesmans'){
        this.salesmans.forEach((item, index) => {
          if(item.id == this.user_id) this.$refs.salesmans.valueLabel = item.name
        });
      } 
    },
    // 選擇縣市時，改變鄉鎮市區和郵遞區號
    countyHandler(e) {
      const county = e
      let countyObj = this.TW_districts.find((item) => item.name === county)
      this.district = countyObj.districts[0].name
      // this.customer.rzipcode = cityObj.districts[0].zip
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
  span{
    color: #FF0000;
  }
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}

textarea {
  border: 1px solid #CCCCCC;
  padding: 7px 13px;
  border-radius: 16px;
  outline: none;
}

.upload {
  @apply border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
  aspect-ratio: 1 / 1;
}

.image_wrap {
  @apply border-2 rounded-xl border-gray-200 overflow-hidden flex items-center justify-center relative;
  cursor: grab;
  aspect-ratio: 1 / 1;

  .image_func {
    @apply absolute
      left-0
      top-0
      w-full
      h-full
      bg-black bg-opacity-50
      transition-all
      flex
      items-center
      justify-center
      opacity-0
      hover:opacity-100;
  }
}

.dragClass {
  background: white;
  opacity: 1 !important;
}

.chosenClass {
  cursor: grabbing !important;
}
</style>
